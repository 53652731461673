import React, { useCallback, useState } from 'react'
import { router } from '@inertiajs/react'
import styles from './SearchBar.module.scss'

export default function SearchBar() {
  const queryParam = new URLSearchParams(window.location.search).get('search')
  const [query, setQuery] = useState(queryParam ? decodeURIComponent(queryParam) : '')
  const [debounceTimeout, setDebounceTimeout] = useState<number | null>(null)

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value

    if (debounceTimeout)
      clearTimeout(debounceTimeout)

    const newTimeout = setTimeout(() => {
      router.get(window.location.pathname, { search: value }, {
        preserveState: true,
        preserveScroll: false,
        // onSuccess: () => {

        // },
      })
    }, 500)

    setDebounceTimeout(newTimeout)
    setQuery(value)
  }, [debounceTimeout])

  return (
    <div className={styles.searchOuter}>
      <div className={styles.search}>
        <input type="text" placeholder="Søg efter et forslag" value={query} onChange={e => handleSearch(e)} />
      </div>
    </div>
  )
}
