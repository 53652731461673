import React from 'react'

import { WhenVisible } from '@inertiajs/react'
import PageTitle from '../../components/PageTitle/PageTitle'
import BillsList from '../../components/BillsList/BillsList'
import type { pageProps } from '../../types/page.type'
import TabNavigation from '../../components/TabNavigation/TabNavigation'
import Hero from '../../components/Hero/Hero'

import SearchBar from '../../components/SearchBar/SearchBar'
import type { Bill } from '../../types/bill.type'
import Layout from './Layout'

export default function Page({ items, page, hasMorePages }: pageProps) {
  const bills = items.map(item => item.data) as Bill[]
  return (
    <Layout>
      <TabNavigation active={3} />

      <Hero />

      <SearchBar />

      <PageTitle title="Tidligere forslag" />

      <BillsList bills={bills} infoElementPlacement={10} />

      {hasMorePages && (
        <WhenVisible
          always
          params={{
            data: {
              page: +page + 1,
            },
            only: ['items', 'hasMorePages', 'page'],
          }}
          fallback={<></>}
        >
          <p>...</p>
        </WhenVisible>
      )}

    </Layout>
  )
}
