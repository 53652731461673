import React from 'react'
import Headline from '../Headline/Headline'
import Video from '../Video/Video'
import styles from './Hero.module.scss'
import HeroVideo from './HeroVideo'

export default function Hero() {
  const [showVideo, setShowVideo] = React.useState(false)

  return (
    <div className={styles.heroOuter}>

      <div className={styles.hero}>
        <Headline text="Hvad vil du gerne forandre?" />

        <p>Ultraforslag giver børn og unge en stemme.</p>
        <p>Skriv dit Ultraforslag til dét, DU gerne vil forandre. Du kan også stemme på andres forslag og dele et link til dit eget, så du får stemmer på det. </p>

        <div className={styles.howItWorks} onClick={() => setShowVideo(true)}>
          <div className={styles.play}>
            <img src="/images/video-thumps/praemisvideo_2.jpg" alt="Ultraforslag illustration" width="80px" />
            <img className={styles.playImg} src="/images/play.png" alt="Afspil video" width="30px" />
          </div>
          <div className={styles.text}>
            <p>Se her hvordan det virker</p>
            <p className={styles.length}>(Varighed 1 min og 17 sek)</p>
          </div>
        </div>

      </div>

      {showVideo && <HeroVideo isOpen={showVideo} setIsOpen={setShowVideo} />}
    </div>
  )
}
