import React from 'react'
import styles from './Select.module.scss'

export default function Select({
  label,
  name,
  value,
  setValue,
  error,
  setError,
  helperText,
  white,
  whiteBackground,
  options,
  required,
}: {
  label?: string
  name: string
  value: string
  setValue: (value: string) => void
  error?: string | false
  setError?: (value: boolean) => void
  helperText?: string
  white?: boolean
  whiteBackground?: boolean
  options: { label: string, value: string }[]
  required?: boolean
}) {
  return (
    <div className={`${styles.selectWrapper} ${white ? styles.white : ''} ${whiteBackground ? styles.whiteBackground : ''}`}>
      {label && (
        <label htmlFor={name} className={`${styles.label} ${error ? styles.error : ''}`}>
          {required && <span className={styles.required}>* </span>}
          {label}
        </label>
      )}

      {helperText && <div className={styles.helperText}>{helperText}</div>}

      <select
        id={name}
        className={`${styles.select} ${error ? styles.error : ''}`}
        name={name}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          setError && setError(false)
        }}
      >
        <option value="" disabled>
          Vælg en mulighed
        </option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}
