import React from 'react'
import { motion } from 'framer-motion'
import BillCard from '../Bill/Bill'
import type { Bill } from '../../types/bill.type'
import Video from '../Video/Video'
import DrtvLink from '../DrtvLink/DrtvLink'
import styles from './BillsList.module.scss'

function InfoElement({
  condition,
  children,
}: {
  condition: boolean
  children: React.ReactNode
}) {
  if (!condition)
    return null

  return (
    <motion.div
      initial={{ y: '100%', opacity: 0 }}
      animate={{ y: '0%', opacity: 1 }}
      transition={{ duration: 0.5 }}
      className={styles.infoElement}
    >
      {children}
    </motion.div>
  )
}

function BillColumn({
  bills,
  withPlace,
  shareable,
  infoElementPlacement,
}: {
  bills: Bill[]
  withPlace?: boolean
  shareable?: boolean
  infoElementPlacement?: number
}) {
  return (
    <>
      {bills.map((bill: Bill) => (
        <div key={`bill-${bill.id}`}>
          <motion.div
            initial={{ y: '100%', opacity: 0 }}
            animate={{ y: '0%', opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <BillCard bill={bill} withPlace={withPlace} shareable={shareable} />
          </motion.div>

          <InfoElement
            condition={
              infoElementPlacement !== undefined
              && ((bill.placement + 1 === infoElementPlacement + 4)
              || (bill.placement > 10 && (bill.placement - 5) % 20 === 0))
            }
          >
            <DrtvLink title={true} />
          </InfoElement>

          <InfoElement
            condition={
              infoElementPlacement !== undefined
              && (bill.placement === infoElementPlacement || bill.placement % 20 === 0)
            }
          >
            <Video
              src="https://nx60199.your-storageshare.de/s/TfZNxFzYWkcQTJL/download/intro.mp4"
              poster="/images/video-thumps/intro.jpg"
              title="Har du selv et godt forslag?"
            />
          </InfoElement>
        </div>
      ))}
    </>
  )
}

export default function BillsList({
  bills,
  withPlace,
  shareable,
  infoElementPlacement,
}: {
  bills: Bill[]
  withPlace?: boolean
  shareable?: boolean
  infoElementPlacement?: number
}) {
  const billsRight = bills.filter(bill => bill.placement % 2 === 0)
  const billsLeft = bills.filter(bill => bill.placement % 2 !== 0)

  return (
    <>
      <div className={`${styles.bills} ${styles.twoColumns}`}>
        <div>
          <BillColumn
            bills={billsLeft}
            withPlace={withPlace}
            shareable={shareable}
            infoElementPlacement={infoElementPlacement}
          />
        </div>
        <div>
          <BillColumn
            bills={billsRight}
            withPlace={withPlace}
            shareable={shareable}
            infoElementPlacement={infoElementPlacement}
          />
        </div>
      </div>

      <div className={`${styles.bills} ${styles.singleColumn}`}>
        <BillColumn
          bills={bills}
          withPlace={withPlace}
          shareable={shareable}
          infoElementPlacement={infoElementPlacement}
        />
      </div>
    </>
  )
}
