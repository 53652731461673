import Cookies from 'js-cookie'
import { convertKeysToSnakeCase } from '../helpers/convertKeysToSnakeCase'
import type { BillColor } from '../types/bill.type'
import type { ParticipantAuthResponse } from './auth'

interface BlllCreateRequest {
  color: BillColor
  title: string
  emoji: string
  text: string
  reason: string
  firstname: string
  city: string
  fullname: string
  age: string
  mobile: string
  parentFullname: string
  parentPhone: string
  permission: boolean
  pin: string
  municipality: string
}

export async function createBill(newBill: BlllCreateRequest): Promise<boolean> {
  try {
    const response = await fetch('/api/bill/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(convertKeysToSnakeCase(newBill)),
    })

    if (response.status === 200) {
      const body = await response.json() as ParticipantAuthResponse
      Cookies.set('token', body.data.token)
    }

    return response.status === 200
  }
  catch (error) {
    throw new Error('Error signing up')
  }
}
