import React, { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import Overlay from '../Overlay/Overlay'
import { getRandomColor } from '../../helpers/color'
import { useIsAuthenticated } from '../../hooks/auth'
import { createBill } from '../../actions/bill'
import type { BillColor } from '../../types/bill.type'
import styles from './NewBill.module.scss'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import Step7 from './Step7'
import Step6 from './Step6'
import Step8 from './Step8'

// Reusable MotionStep component
function MotionStep({
  children,
  stepKey,
  custom,
  variants,
  className,
}: {
  children: React.ReactNode
  stepKey: string
  custom: number
  variants: {
    [key: string]: any
  }
  className: string
}) {
  return (
    <motion.div
      className={className}
      key={stepKey}
      custom={custom}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{ x: { type: 'spring', stiffness: 300, damping: 30 }, opacity: { duration: 0.2 } }}
    >
      {children}
    </motion.div>
  )
}

export default function NewBill({
  isOpen,
  setIsOpen,
  setOpenConsent,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  setOpenConsent: (openConsent: boolean) => void
}) {
  const isAuthentificated = useIsAuthenticated()
  const [step, setStep] = useState(1)
  const [stepExiting, setStepExiting] = useState(1)
  const [authError, setAuthError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const [color, setColor] = useState<BillColor>(getRandomColor() as BillColor)

  const [title, setTitle] = useState('')
  const [emoji, setEmoji] = useState('')
  const [text, setText] = useState('')
  const [reason, setReason] = useState('')
  const [firstname, setFirstname] = useState(isAuthentificated && isAuthentificated.firstname !== null ? isAuthentificated.firstname : '')
  const [city, setCity] = useState(isAuthentificated && isAuthentificated.city !== null ? isAuthentificated.city : '')
  const [fullname, setFullname] = useState(isAuthentificated && isAuthentificated.fullname !== null ? isAuthentificated.fullname : '')
  const [age, setAge] = useState(isAuthentificated && isAuthentificated.age !== null ? isAuthentificated.age : '')
  const [mobile, setMobile] = useState(isAuthentificated ? isAuthentificated.mobile : '')
  const [parentFullname, setParentFullname] = useState(isAuthentificated && isAuthentificated.parent_fullname !== null ? isAuthentificated.parent_fullname : '')
  const [parentPhone, setParentPhone] = useState(isAuthentificated && isAuthentificated.parent_phone !== null ? isAuthentificated.parent_phone : '')
  const [permission, setPermission] = useState(false)
  const [pin, setPin] = useState('')
  const [repeatPin, setRepeatPin] = useState('')
  const [municipality, setMunicipality] = useState(isAuthentificated && isAuthentificated.municipality !== null ? isAuthentificated.municipality : '')

  const variants = {
    enter: (direction: number) => {
      return {
        x: direction > stepExiting ? 100 : -100,
        opacity: 0,
      }
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => {
      setStepExiting(step)

      return {
        zIndex: 0,
        x: step > direction ? 100 : -100,
        opacity: 0,
      }
    },
  }

  const stepTitle = () => {
    switch (step) {
      case 3:
        return 'Sådan ser dit forslag ud'

      case 4:
        return 'Kontaktoplysninger'

      case 5:
        return isAuthentificated ? 'Indtast pinkode' : 'Vælg pinkode'

      case 6:
        return 'Gentag pinkode'

      case 7:
        return 'Sender dit forslag...'

      case 8:
        return 'Sådan!'

      default:
        return 'Skriv dit forslag'
    }
  }

  const handleSubmitBill = async (e?: React.FormEvent) => {
    e?.preventDefault()
    setIsFetching(true)
    setStep(7)
    setAuthError(false)
    const auth = await createBill({
      color,
      title,
      emoji,
      text,
      reason,
      firstname,
      city,
      fullname,
      age,
      mobile,
      parentFullname,
      parentPhone,
      permission,
      pin,
      municipality,
    })

    if (!auth) {
      setIsFetching(false)
      setAuthError(true)
      setStep(5)
      setPin('')
      setRepeatPin('')
    }
    else {
      setTimeout(() => {
        setStep(8)
        setIsFetching(false)
      }, 2000)
    }
  }

  useEffect(() => {
    if (isAuthentificated && pin.length === 4 && step === 5 && !authError)
      void handleSubmitBill()

    if (!isAuthentificated && repeatPin.length === 4 && step === 6 && !authError && pin === repeatPin)
      void handleSubmitBill()
  }, [isAuthentificated, pin, repeatPin])

  return (
    <Overlay isOpen={isOpen} background={color} setIsOpen={setIsOpen} title={stepTitle()} direction="bottom" hideClose={step === 6 || step === 7}>

      <AnimatePresence initial={false} custom={step} mode="wait">
        {step === 1 && (
          <MotionStep stepKey="step1" custom={1} variants={variants} className={styles.step}>
            <Step1
              setStep={setStep}
              color={color}
              setColor={setColor}
              emoji={emoji}
              setEmoji={setEmoji}
              title={title}
              setTitle={setTitle}
            />
          </MotionStep>
        )}

        {step === 2 && (
          <MotionStep stepKey="step2" custom={2} variants={variants} className={styles.step}>
            <Step2
              setStep={setStep}
              color={color}
              text={text}
              setText={setText}
              reason={reason}
              setReason={setReason}
              firstname={firstname}
              setFirstname={setFirstname}
              city={city}
              setCity={setCity}
              municipality={municipality}
              setMunicipality={setMunicipality}
            />
          </MotionStep>
        )}

        {step === 3 && (
          <MotionStep stepKey="step3" custom={3} variants={variants} className={`${styles.step} ${styles.backgroundWhite}`}>
            <Step3
              setStep={setStep}
              emoji={emoji}
              title={title}
              color={color}
              text={text}
              reason={reason}
              firstname={firstname}
              city={city}
            />
          </MotionStep>
        )}

        {step === 4 && (
          <MotionStep stepKey="step4" custom={4} variants={variants} className={`${styles.step} ${styles.backgroundWhite}`}>
            <Step4
              setStep={setStep}
              fullname={fullname}
              setFullname={setFullname}
              age={age}
              setAge={setAge}
              mobile={mobile}
              setMobile={setMobile}
              parentFullname={parentFullname}
              setParentFullname={setParentFullname}
              parentPhone={parentPhone}
              setParentPhone={setParentPhone}
              permission={permission}
              setPermission={setPermission}
              setOpenConsent={setOpenConsent}
            />
          </MotionStep>
        )}

        {step === 5 && (
          <MotionStep stepKey="step5" custom={5} variants={variants} className={`${styles.step} ${styles.backgroundWhite}`}>
            <Step5
              setStep={setStep}
              pin={pin}
              setPin={setPin}
              isAuthentificated={isAuthentificated}
              submitBill={handleSubmitBill}
              authError={authError}
              isFetching={isFetching}
            />
          </MotionStep>
        )}

        {step === 6 && (
          <MotionStep stepKey="step6" custom={6} variants={variants} className={`${styles.step} ${styles.backgroundWhite}`}>
            <Step6
              setStep={setStep}
              pin={pin}
              repeatPin={repeatPin}
              setRepeatPin={setRepeatPin}
              submitBill={handleSubmitBill}
              isFetching={isFetching}
            />
          </MotionStep>
        )}

        {step === 7 && (
          <MotionStep stepKey="step7" custom={7} variants={variants} className={`${styles.step} ${styles.backgroundWhite}`}>
            <Step7 />
          </MotionStep>
        )}

        {step === 8 && (
          <MotionStep stepKey="step8" custom={8} variants={variants} className={`${styles.step} ${styles.backgroundTurquoise}`}>
            <Step8 />
          </MotionStep>
        )}
      </AnimatePresence>

    </Overlay>
  )
}
