import React from 'react'

import PageTitle from '../../components/PageTitle/PageTitle'
import BillsList from '../../components/BillsList/BillsList'
import type { pageProps } from '../../types/page.type'
import TabNavigation from '../../components/TabNavigation/TabNavigation'
import Hero from '../../components/Hero/Hero'
import SearchBar from '../../components/SearchBar/SearchBar'
import Layout from './Layout'

export default function Page({ bills: initialBills }: pageProps) {
  const [bills, setBills] = React.useState(initialBills)

  const handleSetBills = (bills: any) => {
    // console.log(bills)
    setBills(bills)
  }

  // console.log(bills)

  return (
    <Layout>
      <TabNavigation active={1} />

      <Hero />

      <SearchBar setBills={handleSetBills} />

      <PageTitle title="Nyeste forslag" />

      <BillsList bills={bills} infoElementPlacement={6} random={true} />

    </Layout>
  )
}
