import React from 'react'
import Video from '../Video/Video'
import Overlay from '../Overlay/Overlay'
import styles from './Hero.module.scss'

export default function HeroVideo({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) {
  return (

    <Overlay
      isOpen={isOpen}
      background="overlay-background"
      setIsOpen={setIsOpen}
      title="Se her hvordan det virker"
      fitContent={true}
    >
      <div className={styles.introVideo}>
        <Video
          src="https://nx60199.your-storageshare.de/s/FSZQgyReFYX57JJ/download/praemisvideo_2.mp4"
          autoplay={true}
        />
      </div>
    </Overlay>
  )
}
